/**
 * CSS breakpoints
 * XS: 375px
 * SM: 667px
 * MD: 768px
 * LG: 1024px
 * XL: 1366px
 */

:root {
  font-size: 24px;
  font-family: Bold, Arial, Helvetica, sans-serif;
}

html,
body,
body > div {
  height: 100%;
  line-height: 1;
}

body {
  padding: 50px 15px;
  box-sizing: border-box;

  color: #d6e5e5;
  background-color: #1f3246;
}

@media screen and (min-width: 375px) {
  :root {
    font-size: 36px;
  }

  body {
    padding: 30px 15px;
  }
}

@media screen and (min-width: 667px) {
  :root {
    font-size: 48px;
  }

  body {
    padding: 50px 35px;
  }
}

@media screen and (min-width: 768px) {
  :root {
    font-size: 60px;
  }

  body {
    padding: 75px 65px;
  }
}

@media screen and (min-width: 1024px) {
  :root {
    font-size: 72px;
  }

  body {
    padding: 75px 80px;
  }
}

@media screen and (min-width: 1366px) {
  :root {
    font-size: 84px;
  }

  body {
    padding: 75px 110px;
  }
}

h1 {
  margin: 0;
}
